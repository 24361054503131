.card-wrapper {
  max-width: 20em;
  min-height: 30em;
  display: flex;
  flex-direction: column;
  margin: 2em;
  border-radius: 30px;
  overflow: hidden;
  background: aliceblue;
  box-shadow: 0px 5px 10px 2px rgba(7, 7, 7, 0.2);
}

.card-heading {
  padding: 0 1em;
}

.card-description {
  padding: 0 1em;
}

.card-date {
  padding: 0 1em;
}

.card-button-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card-button {
  margin: 0 3em 1em;
  min-width: 3em;
  background: chartreuse;
  box-shadow: 0px 5px 10px 2px rgba(7, 7, 7, 0.2);
  border-radius: 0.25em;
  cursor: pointer;
  line-height: 2em;
  padding: 0 1em;
}

.card-button:hover {
  background: green;
}
