.search-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.search-bar-input {
  width: 30%;
  line-height: 2em;
}
